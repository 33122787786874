<template>
  <view-abnormal />
</template>

<script>
import ViewAbnormal from './modules/view-abnormal'

export default {
  components: {
    ViewAbnormal
  }
}
</script>
